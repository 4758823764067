.column3 {
    float: left;
     width: 70%; 
    padding: 10px;
    /* min-height: 100px; */
    height: auto;
  }

  .MuiDialog-paperWidthSm{
    max-width:900px!important;
  }

  .column3 div {
    /* width: 90%; */
    /* word-break: break-word; */
    margin-right: 2px;
    text-align: left;
   
  }

  .display-non-column3{
    display: none;
  }

  .only-column2 {
    float: left;
    width: 97%;
    /* padding: 10px; */

    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    /* padding: 20px; */
    text-align: left;

    overflow: auto;
     max-height: 900px; 
  }

  .display-non-column2{
    display: none;
  }

  .only-column3 {
    float: left;
     width: 97%; 
    /* padding: 10px; */
    /* min-height: 100px; */
    height: auto;
  }
.column4{
  display: none;
}


em {
  font-weight: bold;
}
.only-column4{
  display: block;
  width: 90%;
  float: left;
  text-align: center;
}
  .column50 {
    float: left;
    width: 50%;
    padding: 10px;
     height: 100px; 

     
  }
  .column2 {
    float: left;
    width: 40%;
    /* padding: 10px; */

    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    /* padding: 20px; */
    text-align: left;

    overflow: auto;
     max-height: 900px; 
  }
 
  .column1 {
      float: left;
      width: 1%;
      padding: 5px;

     
    }

   /* #divcon p{
      text-indent: 40px; 
    } */
    .row{
      display: flex;
    }
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
  }
  
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
  }
  