#btnPhCalcPredict{
    color: #fff!important;
    background-color: #3f51b5!important;
}
#btnPhCalcReset{
    color: #fff!important;
    background-color: #3f51b5!important;
} 
#para{
     line-height: 19px;
    position: absolute;
    top: 60px;
    font-size: 13px;
    right: -13px;
    z-index: 1;
    color: white;
    text-align: center;
    display: flex;
}
#para p{
    padding: 3px;
    border-radius: 3px;
}