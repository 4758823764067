.chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.legend {
    display: block;
    margin: 10px;
}

.chart {
    border: 1px solid silver;
    border-radius: 5px;
    background-color: white;
}
