
[class^="makeStyles-appBarShift-"], [class*=" makeStyles-appBarShift-"]{
    width: calc(100% - 280px)!important;
    background-color: red!important;
}
.MuiAppBar-colorPrimary{
    background-color:#fbce07!important ;
    color: #595959!important ;
}

#linkCards>.MuiPaper-root {
    margin-right: 20px;
}

/* 
.MuiDrawer-paper [class^="makeStyles-drawerHeader"]{
    background-color:#fbce07!important ;
    
} */

.MuiTypography-gutterBottom {
    color: tomato;
}

#headerAppbar .MuiPaper-root>div:first-of-type  {  background-color:#f2f2f2!important ; }
header{max-height: 64px;}
.MuiAppBar-root .MuiSvgIcon-root{
    width: 1.2em;
    height: 1.2em;
}
.selected-menu{
    border-bottom: solid 2px tomato!important ;
    color:tomato!important;
}
.MuiDrawer-paperAnchorDockedLeft{
    background: #5f6b6d!important;
    color: white!important;
    width: 280px!important;
}
.MuiListItemText-inset {
    padding-left: 20px!important;
}
.MuiListItemText-primary{
    font-size: .875em!important;
}

.MuiTypography-paragraph {
    margin-bottom: 16px;
    margin-left: 280px;
}
.MuiTableHead-root{
    background-color:#fbce07!important ;
}

.MuiTableCell-paddingNone{
    padding: 10px!important;
}
.selectedMenuItem{
border:2px #FFD500 solid!important;
background-color: #FFD500!important;
color:#404040!important;

}
.selectedMainMenu{
    /* border-width: 2px!important;
    border-color: #FFD500!important;
    border-style: solid!important; */
    /* border:2px #FFD500 solid!important; */
background-color: darkgray!important;
color:#404040!important;
}
.show-spinner{
    text-align: center;
    display: block;
  }
  .hide-spinner{
    display: none;
  }

  .MuiTableCell-head{
    white-space: nowrap;
  }
  .MuiTableCell-alignRight{
    text-align: center!important;
  }

  .MuiButton-containedPrimary{
    color: #404040!important;
    background-color:#fbce07!important ;
  }

  .MuiFormGroup-root{
      display:unset!important;
  }
  .MuiRadio-colorSecondary.Mui-checked{
      color: #fbce07!important ;
  }

  #divCSVDownload a {
   text-decoration: unset!important;
}
#linkCards>.MuiPaper-root{
    width: 300px;
}
.MuiTableCell-alignCenter{
    text-align: left!important;
}

.MuiSwitch-colorSecondary.Mui-checked{
    color:#fbce07!important;
   }   
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
background-color:#fbce07!important ;
   }

   
    .seperator {
        height: 500px;
        width: 1px;
        background: black;
        margin: 0 auto;
        margin-left: 34%;
        position: absolute;
        
      }
#sliderInputDiv .MuiInputBase-root{
    width: 150px;
}

.MuiPaper-outlined{
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)
}

.MatLogoText{
    font-family: ui-sans-serif;
    font-weight: bold;
    font-size: 50px;
    display: inline-block;
}

.MatLogoImg{
    height: 48px;
    margin-top: 26%;
    margin-right: 15px;
}

.containterContent1{
    color: grey;
    margin-right: 40px;
    margin-left: -25px;
    height: 110px;
    min-width: 105px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.highlowCardValue{
    font-size: 30px;
    margin-top: 10px;
    font-size: 1vw;
}

.highlowCardHead{
    font-size: 18px;
    margin-top: 10px;
}

.authErrorDiv{
    width: 550px;  
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 25px;
    border: 2px solid grey;
    padding: 20px;
    height: 250px;
}

.sqRootBold{
    font-size:30px;color:rgb(0, 0, 0);font-style:normal;font-weight:bold;
}

.graphsDisp{
    max-height: 80%;
    max-width: 80%;
    /* height: 900px; */
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    margin-left: 50px;
    margin-right: 50px;
}
.graphsDisp1{
    max-height: 80%;
    max-width: 80%;
    height: 500px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    margin-left: 50px;
    margin-right: 50px;
}