/* .makeStyles-root-106  */
/* [class*="makeStyles-root-"] */

#divSearchBar  .MuiPaper-elevation1{
    width: 700px!important;
    display: flex;
    padding: 2px 4px;
    align-items: center;
    width: 50%!important;
    margin: auto;
    margin-left: 20px;

    margin-top: 50px;
} 
#divSearchBar .MuiInputBase-input{
    background-color: white!important;
}
.pdfIconCss {
    max-width: 20px;
    max-height: 20px;
    display: inline;
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -3px;
  }
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
  }
  
  
  
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    /* -webkit-font-feature-settings: 'liga'; */
    -webkit-font-smoothing: antialiased;
}
.column div {
    width: unset!important;
}
#divDocResult div .MuiToolbar-gutters{
    display: none;
}
#divDocResult div .MuiTablePagination-toolbar{
    display: flex!important;
}

#divContentResult div .MuiToolbar-gutters{
    display: none;
}
#divContentResult div .MuiTablePagination-toolbar{
    display: flex!important;
}
/* .MTableToolbar-root-140  */
/* [class*="MTableToolbar-root-"]{
    display: none!important;
} */
#divContentResult .MuiTableHead-root{
    display: none!important;
}

#divContentResult .MuiTableCell-alignLeft {
  
    border: none!important;
}
.MuiIcon-root {
    width: 1em;
    height: 1em;
    overflow: hidden;
    font-size: 1.5rem;
    flex-shrink: 0;
    user-select: none;
}
.show-spinner{
    text-align: center;
    display: block;
  }
  .hide-spinner{
    /* align-items: center; */
    display: none;
  }
  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
  }
  
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
  }
  